.b {
  border: 1px solid red;
}
.App {
  font-family: 'Source Sans Pro', sans-serif;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  color: #22323d;
}
.header {
  background-color: black;
  color: #fff;
  font-weight: 600;
}
.header header div div span {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 8px 15px;
  font-size: 12px;
}
.breadcrumb-courses {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.course-name h1 {
  font-size: 36px;
  font-weight: 600;
}
.course-name span {
  color: #939393;
  font-size: 20px;
}
.basic-tabs {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.module-heading {
  display: block !important;
  font-size: 20px;

  font-weight: 500;
  letter-spacing: 1px !important;
}
.module-heading-icons {
  display: block !important;
  font-size: 16px;
  color: #515152;
  margin-right: 50px;
  font-weight: 500;
  letter-spacing: 1px !important;
}
.submodule-heading {
  display: block !important;
  font-size: 17px !important;
  color: #10c3f8 !important;
  margin: 15px 0px 0px 0px !important;
}
.submodule-heading-li {
  color: #939393 !important;
  font-size: 17px !important;
}
.submodule-heading-li div {
  min-width: unset !important;
  padding-right: 10px;
  color: #939393;
  font-size: 17px;
}
.accordion-heading {
  min-height: unset !important;
  height: unset !important;
}
.margin-acc div {
  margin: 0px !important;
}
.margin-acc div {
  margin: 0px !important;
}
.margin-acc-2 div {
  margin: 0px 0px 15px 0px !important;
}
.border-right {
  border-right: 2px solid #939393ab;
}
.overview-key-points li div span {
  font-size: 15px !important;
  color: inherit;
}
.course-feature {
  margin: 0px 0px;
}
.course-feature span {
  font-size: 14px;
}
.course-feature-hr {
  margin: 8px 0px !important;
}
.instructor-name-div {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.video-details h1 {
  font-size: 17px;
}
.video-details span {
  color: #939393;
  font-size: 13px;
}
.footer {
  background: linear-gradient(
    to right,
    rgba(101, 216, 239, 1) 0%,
    rgba(94, 84, 243, 1) 100%
  );
  color: #fff;
}
.footer a,
.footer p {
  display: block;
  margin-top: 15px;
  font-size: 16px;
}
.footer a:hover {
  display: block;
  color: #fff;
  margin-top: 15px;
}
.nav-lg-btn button {
  text-transform: none !important;
}
.responsive div div {
  overflow-x: scroll !important;
}
.module-info {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .header-none-sm {
    display: none;
  }
  .reverse-col {
    flex-flow: column-reverse;
  }
  
  .module-heading-icons {
  }
}
@media (max-width: 576px) {
  .module-info {
    display: block;
    align-items: unset;
  }
  .module-heading-icons{
    margin-right: 0px;
  }
  .tabs-panel > div{
    padding: 20px 0px !important;
  }
}
@media (max-width: 992px) {
  .border-right {
    border-right: none !important;
  }
}
